var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"mb-10",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h5 primary--text"},[_vm._v("ลูกค้าเพิ่มตัง")]),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{staticClass:"ml-10",attrs:{"prepend-inner-icon":"mdi-magnify","label":"ค้นหา ชื่อร้าน","outlined":"","rounded":"","dense":"","single-line":"","hide-details":""},on:{"keyup":_vm.getData},model:{value:(_vm.filter.start_with),callback:function ($$v) {_vm.$set(_vm.filter, "start_with", $$v)},expression:"filter.start_with"}})],1),_c('v-spacer'),_c('DatePicker',{attrs:{"label":"วันเริ่มต้น","value":_vm.filter.start_date,"hideDetails":""},on:{"onChange":function (val) {
          _vm.filter.start_date = val;
          _vm.getData(true);
        }}}),_c('DatePicker',{attrs:{"label":"วันสิ้นสุด","value":_vm.filter.end_date,"hideDetails":""},on:{"onChange":function (val) {
          _vm.filter.end_date = val;
          _vm.getData(true);
        }}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"id","loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","item-key":"_id"},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function (x) { return x._id; }) .indexOf(item._id) + 1)+" ")]}},{key:"item.phone_number",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.phoneNumberFormat(item.phone_number)))])]}},{key:"item.start_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.start_date))+" ")]}},{key:"item.end_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.end_date))+" ")]}},{key:"item.max_bills",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_unlimit ? 'Unlimit' : item.max_bills)+" ")]}},{key:"item.product_type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_type == '0' ? 'ครั้งเดียว' : '')+" "+_vm._s(item.product_type == '1' ? 'รายเดือน' : '')+" "+_vm._s(item.product_type == '2' ? 'รายปี' : '')+" ")]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }